<template>
  <div
    class="userSearch"
    v-watermark
  >
    <commonNavBar :title="'任务搜索'"></commonNavBar>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="value"
          show-action
          :placeholder="placeholder"
          left-icon=""
          @search="onSearch"
        >
          <template #action>
            <div @click="searchBtn">搜索</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                v-model="value1"
                :options="option1"
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <!-- 搜索历史 -->
      <div
        class="searchBar"
        v-if="historyList.length"
      >
        <span class="label">搜索历史</span>
        <svg-icon
          icon-class="delet"
          className="myIcon"
          @click="delClick"
        />
      </div>
      <div
        class="searchList"
        v-if="historyList.length"
      >
        <van-cell-group>
          <van-cell
            :title="item"
            v-for="(item, index) in historyList"
            :key="index"
            @click="historyClick(item)"
          />
        </van-cell-group>
      </div>
      <div
        v-else
        class="emptyBox"
      >
        <van-empty
          image="search"
          description="没有任何历史搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'userSearch',
  data() {
    return {

      value: '',
      value1: 0,
      option1: [
        { text: '单位', value: 0 },
        { text: '任务单', value: 1 }
      ],
      historyList: []
    }
  },
  watch: {
    value1: {
      handler: function (val, oldVal) {
        if (val === 0) {
          this.historyList = ['单位', '单位', '单位']
        }
        if (val === 1) {
          this.historyList = ['任务单', '任务单', '任务单']
        }
      },
      immediate: true
    }
  },
  computed: {
    placeholder() {
      if (this.value1 === 0) {
        return '请输入单位编号或名称'
      } else {
        return '请输入任务单号'
      }
    }
  },
  methods: {
    // 删除历史
    delClick() {
      Dialog.confirm({
        title: '删除搜索历史',
        message: '确认清除历史记录？'
      })
        .then(() => {
          this.historyList = []
        })
        .catch(() => { })
    },
    // 搜索
    onSearch(val) {
      if (val) {
        this.$router.push({
          path: '/searchResult',
          query: {
            text: val,
            type: this.value1
          }
        })
      }
    },
    // 点击搜索按钮
    searchBtn() {
      this.onSearch(this.value)
    },
    // 点击历史
    historyClick(val) {
      this.onSearch(val)
    }
  }

}
</script>

<style lang="scss" scoped>
.userSearch {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 10px;
            }
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        margin-bottom: 10px;

        .label {
          font-size: 14px;
          color: #aaaaaa;
        }

        .myIcon {
          width: 24px;
          height: 24px;
        }
      }

      .emptyBox {
        height: calc(100% - 54px);
        position: relative;

        .van-empty {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
